<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          回调记录
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->

          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">

            <template v-slot:item.merchantId="{ item }">
              <div>
                {{  item.merchantName  }} [{{  item.merchantId  }}]
              </div>
            </template>

            <template v-slot:item.createTime="{ item }">
              {{  item.createTime | formatDate  }}
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-datepicker locale="zh" v-model="filter.startTime" placeholder="起始日期" :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"></b-form-datepicker>

                <b-form-datepicker class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0" locale="zh"
                  v-model="filter.endTime" placeholder="结束日期" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"></b-form-datepicker>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="search()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>


  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {

      filter: {
        conditions: [{
          value: null,
          text: "检索条件"
        },
        {
          value: "merchantId",
          text: "商户ID"
        },
        {
          value: "orderId",
          text: "平台订单号"
        },

        ],
        field: null,
        keyword: "",
        startTime: "",
        endTime: "",
      },

      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 20,
        pagePack: {
          lastPage: null,
          firstId: null,
          lastId: null
        },
        headers: [{
          text: "商户ID",
          value: "merchantId"
        },
        {
          text: "订单号",
          value: "orderId"
        },
        {
          text: "通知URL",
          value: "notifyUrl"
        },
        {
          text: "回调参数",
          value: "notifyData"
        },
        {
          text: "回调结果",
          value: "notifyResult"
        },
        {
          text: "回调时间",
          value: "createTime"
        },

        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
      },
    };
  },
  mounted() {

  },
  created() { },

  methods: {
    search() {
      this.table.pagePack = {};
      if (this.table.pagination.page == 1) {
        this.getData();
      }
      this.table.pagination.page = 1;
    },

    getData() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }

      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        startTime: startTime,
        endTime: endTime,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;
      params['pack.lastPage'] = this.table.pagePack.lastPage;
      params['pack.firstId'] = this.table.pagePack.firstId;
      params['pack.lastId'] = this.table.pagePack.lastId;

      ApiService.query("/api/order/notify/manage/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
        this.table.pagePack = data.result.pack;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    }
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>